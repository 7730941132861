import { Injectable, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { RolesRequestModel } from './API/roleModel';
import { CurrentUserViewModel } from './API/userModel';

@Injectable({
  providedIn: 'root'
})
export class GvarService implements OnInit {
  ServiceModelResponseFooterReplica: any;
  ServicesResponseReplica: any;
  MenuResponseReplica: any;

  EntryAdded: Subject<any> = new Subject<any>();
  STATIONid: number = 0;
  GoodsCallFrom: string = "";
  private Roles: RolesRequestModel[] = [];
  G_IsRunning: boolean = false;
  companyID: number = 0;
  OutletID: number = 0;
  OutletAddress: string = "";
  Currency: string = "";
  companyName: string = "";
  OutletName: string = "";
  locationID: number = 0;
  userName: any;
  UserId: any;
  currentUser: CurrentUserViewModel;
  serverURL: string = environment.serverURL;
  serverURLLogin: string = environment.serverURLLogin;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.currentUser = new CurrentUserViewModel();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.userName = localStorage.getItem('userName');
      this.UserId = localStorage.getItem('UserId');
      const roles = localStorage.getItem('userRoles');
      this.Roles = roles ? JSON.parse(roles) : [];
    }
  }

  roleMatch(allowedRoles: any): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const temp = localStorage.getItem('userRoles');
      if (temp === "undefined") {
        return false;
      }
      this.Roles = JSON.parse(temp || '[]');

      return this.Roles.some(role => role.RoleId === allowedRoles);
    }
    return false;
  }

  get canAddEditOwner() {
    return this.roleMatch(1);
  }
  get canGetOwner() {
    return this.roleMatch(2);
  }
  get canAddEditCompany() {
    return this.roleMatch(3);
  }
  get canGetCompany() {
    return this.roleMatch(4);
  }
  get canAddEditOutlet() {
    return this.roleMatch(5);
  }
  get canGetOutlets() {
    return this.roleMatch(6);
  }

  setDetail(stationid: any) {
    this.STATIONid = stationid;
    this.EntryAdded.next(stationid);
  }
}
